<template>
  <v-card
    class="mt-0"
    elevation="5"
    :loading="loading"
  >
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
      >
        <v-icon
          size="45"
          :class="colorBox"
        >
          {{ iconBox }}
        </v-icon>
        <div class="mr-5">
          <v-progress-circular
            v-if="loading"
            color="primary"
            :size="50"
            :value="progressTxt"
            indeterminate
            class="vpc-importacoes-dados"
          >
            <label style="font-size: 10px">{{ progressTxt }}%</label>
          </v-progress-circular>
        </div>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-0">
          Importação: {{ statusTxt }}
        </v-list-item-title>
        <v-progress-linear
          :value="progress"
          :color="colorProgressLinear"
        />
        <v-list-item-subtitle>
          <span v-if="totalLinhas">
            {{ linhasProcessadas | parseNumberToIntegerBR }} linhas processadas
            de um total de {{ totalLinhas | parseNumberToIntegerBR }}:
            {{ progressTxt }}%
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    },
    contadores: {
      type: Object
    }
  },
  computed: {
    statusTxt() {
      return this.status.toLowerCase();
    },
    iconBox() {
      switch (this.statusTxt) {
        case 'aguardando':
          return 'mdi-clock-outline';
        case 'sucesso':
          return 'mdi-check-bold';
        case 'executando':
          break;
        case 'falha':
          return 'mdi-alert';
      }
      return 'mdi-alert';
    },
    colorBox() {
      switch (this.statusTxt) {
        case 'aguardando':
          return 'border-c-blue';
        case 'sucesso':
          return 'border-c-green';
        case 'executando':
          return 'border-c-primary';
        case 'falha':
          return 'border-c-red';
      }
      return 'border-c-red';
    },
    colorProgressLinear() {
      switch (this.statusTxt) {
        case 'aguardando':
          return '#17a2b8';
        case 'sucesso':
          return '#438102';
        case 'executando':
          return '#003a63';
        case 'falha':
          return 'red';
      }
      return 'red';
    },
    totalLinhas() {
      let { linhasImportaveis, numeroLinhas } = this.contadores;
      let totalLinhas = linhasImportaveis ? linhasImportaveis : numeroLinhas;
      return totalLinhas;
    },
    linhasProcessadas() {
      let { linhasNaoImportadas, linhasImportadas } = this.contadores;
      let linhasProcessadas = linhasImportadas + linhasNaoImportadas;
      return linhasProcessadas;
    },
    progress() {
      let progress = 0;
      if (this.totalLinhas) {
        progress = (this.linhasProcessadas / this.totalLinhas) * 100;
      }
      return progress >= 100 ? 100 : progress;
    },
    progressTxt() {
      return this.progress.toFixed(2);
    },
    loading() {
      return this.statusTxt == 'executando' ? true : false;
    }
  }
};
</script>

<style>
.vpc-importacoes-dados div label {
  font-size: 8.5px !important;
}
</style>
